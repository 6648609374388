// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import Services from 'components/units/unitPages/unit/services';
import TechnicalExcellence from 'components/cdi/technicalExcellence';
import WhyChooseOurCDI from 'components/cdi/WhyChooseOurCDI';
import OthersCids from 'components/cdi/othersCdis';
import ListPosts from 'components/blog/listPosts';
import OpeningHours from 'components/cdi/openingHours';
import DiscoverOurDifferentials from 'components/twoColumns';
import Components from 'components/CmsComponents/';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';

// Image
import firstBlob from 'images/blobs/Slime_Pink.webp';
import secBlob from 'images/blobs/Slime_Blue.webp';
import MapImage from 'images/mapsImages/cdi-bsb.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: ${props => (props.isFitCardButtonPages ? '1.875rem' : '0')};
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];

    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const components = page[0]?.components ?? [];
  const featuredMarketingContent = page[0]?.featuredMarketing;

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / span 12'} isCDIUnits>
        {featuredMarketingContent && (
          <FeaturedMarketing content={featuredMarketingContent} />
        )}
      </Part>
      <Part
        gridColumn={'2 / -2'}
        Bottom={'2.5rem'}
        isNotBorder
        className={'cardsServices'}
        isFitCardButtonPages
        borderBottom
      >
        <Services
          markdown={getMarkdown(page, 'ckl9mys8809dt0a770iran2in', true)}
          fragments={getMarkdown(page, 'ckl9mys8809dt0a770iran2in')}
          cardHowCanIHelp
          noJustifyContent
          isCDIUnits
          titleDeskWith="100%"
          isSlideBrasilia
          isAdjustmentInCssLayout
          isAdjustmentInCssLayoutMobile
          marginLeftCards="1rem"
          marginRightCard="30px"
          widthTextHiperlink
          isCentroDiagnostico
          isFitCardButtonPages
        />
      </Part>
      <Part gridColumn={'2 / -2'} Bottom={'2.5rem'} isNotBorder>
        <TechnicalExcellence
          markdown={getMarkdown(page, 'ckl9n6v4g0adv0a160esd63sh', true)}
          fragments={getMarkdown(page, 'ckl9n6v4g0adv0a160esd63sh')}
          asset={getMarkdown(page, 'ckl9n6v4g0adv0a160esd63sh', false, true)}
          blob={firstBlob}
        />
      </Part>
      <Part gridColumn={'1 / -1'}>
        <WhyChooseOurCDI
          markdown={getMarkdown(page, 'cklawqiwg53l40a6813grve7n', true)}
          fragments={getMarkdown(page, 'cklawqiwg53l40a6813grve7n')}
          isCDIBrasilia
        />
      </Part>
      <Part gridColumn={'2 / -2'}>
        <DiscoverOurDifferentials
          markdown={getMarkdown(page, 'cklazzohs5rzs0a689wplyxtn', true)}
          isBorder
        />
      </Part>
      <Part
        gridColumn={'2 / -2'}
        Bottom={'2.5rem'}
        isNotBorder
        id="opening-hours"
      >
        <OpeningHours
          markdown={getMarkdown(page, 'cklb05n005va40b26hjkxd26a', true)}
          fragments={getMarkdown(page, 'cklb05n005va40b26hjkxd26a')}
          zoom={16}
          location={{ lat: -15.835063, lng: -47.9114232 }}
          photoMap={MapImage}
          isCDIBrasilia
          isNotBorder
        />
      </Part>
      <Part gridColumn={' 2 / -2'}>
        <OthersCids
          markdown={getMarkdown(page, 'cklb0b27s5ofy0a16mexec6pu', true)}
          fragments={getMarkdown(page, 'cklb0b27s5ofy0a16mexec6pu', false)}
        />
      </Part>
      <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
        {components.length >= 1 &&
          components?.map(component => {
            return Components(component);
          })}
      </Part>
      {page?.[0]?.posts.length > 0 && (
        <Part gridColumn={'2 / -2'} padding borderTop>
          <ListPosts
            title="Blog do Sírio-Libanês"
            postsToShow={3}
            posts={page?.[0]?.posts}
          />
        </Part>
      )}
    </GridContainer>
  );
};

const CDIBrasilia = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  // ====== WEBCHAT ====== //
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705/assets/js/bootstrap.min.js';
    document.body.appendChild(script);

    const handleScriptLoad = () => {
      try {
        window.embeddedservice_bootstrap.settings.language = 'pt_BR';
        window.embeddedservice_bootstrap.init(
          '00D41000000exwd',
          'Webchat_Agendamento_Sites',
          'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705',
          {
            scrt2URL: 'https://hospitalsiriolibanes.my.salesforce-scrt.com',
          }
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    };

    script.addEventListener('load', handleScriptLoad);

    return () => {
      script.removeEventListener('load', handleScriptLoad);
      document.body.removeChild(script);

      [
        'embedded-messaging',
        'embeddedMessagingSiteContextFrame',
        'embeddedMessagingFilePreviewFrame',
      ].forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          document.body.removeChild(element);
        }
      });
    };
  }, []);

  const getImage = getMarkdown(page, 'ckl9mtm9s09740a688bvstr0s', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckl9mnx0w08ht0a68wln7bp1g" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <CDIBrasilia
            page={response.gcms.site.pages}
            location={props.location}
            {...props}
          />
        );
      }}
    />
  );
};
